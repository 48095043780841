import React, { useEffect, useContext } from "react";
import { AppContext } from "../../store/appContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatchHelpers } from "../../store/dispatchhelper";
import Banner from "../../components/banner/banner";
import WebLoader from "../../components/webLoader/WebLoader";
import "./renovation.scss";
import rightArrow from "./../../assets/images/right-arrow-long.svg";

const Renovation = () => {
  const {
    state: { baseUrl, renovation, lang },
  } = useContext(AppContext);
  const { dispatchAction } = useDispatchHelpers();
  const isDataReady = Object.keys(renovation).length > 0;
  const navigate = useNavigate();
  useEffect(() => {
    axios.get(`${baseUrl}immobielien-rennovation`).then((response) => {
      dispatchAction("setRenovationData", response?.data?.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    // Find all span elements inside a div with class "page-container" that have style="color: white;"
    const spans = document.querySelectorAll(
      '.page-container span[style="color: white;"]'
    );
    // Add a class to each of these spans
    spans.forEach((span) => {
      span.classList.add("white-text");
    });
  }, [renovation]);
  return (
    <React.Fragment>
      <WebLoader isLoading={!isDataReady} />
      <div className="page renovation-page">
        {renovation && isDataReady && (
          <React.Fragment>
            <Banner
              mediaUrl={renovation.banners[0].path}
              title={renovation.banners[0]["title_" + lang]}
            />
            <div className="page-container" id="page-container">
              <h2
                className="renovation-header"
                dangerouslySetInnerHTML={{
                  __html:
                    renovation.immobielien_rennovation.header["title_" + lang],
                }}
              />
              <div className="category-section">
                <div
                  className="category-card-long"
                  onClick={() =>
                    navigate(
                      `/immobielien-rennovation/${renovation.immobielien_rennovation.data.rennovation_details_data[0].id}`
                    )
                  }
                >
                  <img
                    src={
                      renovation.immobielien_rennovation.data
                        .rennovation_details_data[0].path
                    }
                    alt="category-icon"
                  />
                  <div className="card-desc">
                    <img src={rightArrow} alt="right-arrow" />
                    <p
                      dangerouslySetInnerHTML={{
                        __html:
                          renovation.immobielien_rennovation.data
                            .rennovation_details_data[0]["title_" + lang],
                      }}
                    />
                  </div>
                </div>
                <div className="category-card-four">
                  {renovation.immobielien_rennovation.data.rennovation_details_data
                    .slice(1)
                    .map((item, i) => {
                      return (
                        <div
                          key={`cat-${i}`}
                          className="category-card"
                          onClick={() =>
                            navigate(`/immobielien-rennovation/${item.id}`)
                          }
                        >
                          <img src={item.path} alt="category-icon" />
                          <div className="card-desc">
                            <img src={rightArrow} alt="right-arrow" />
                            <p
                              dangerouslySetInnerHTML={{
                                __html: item["title_" + lang],
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="service-section-container">
                <h2
                  className="renovation-header"
                  dangerouslySetInnerHTML={{
                    __html:
                      renovation.immobielien_rennovation.data.services
                        .services_title["service_title_" + lang],
                  }}
                />
                <div className="services-section">
                  {renovation.immobielien_rennovation.data.services.services_data.map(
                    (item, i) => {
                      return (
                        <div key={`cat-${i}`} className="service-card">
                          <h3
                            className="card-title"
                            dangerouslySetInnerHTML={{
                              __html: item["service_title_" + lang],
                            }}
                          />
                          <p
                            className="card-desc"
                            dangerouslySetInnerHTML={{
                              __html: item["service_description_" + lang],
                            }}
                          />
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

export default Renovation;
