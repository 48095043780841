import React, { useEffect, useContext } from "react";
import "./trueHand.scss";
import { AppContext } from "../../store/appContext";
import axios from "axios";
import { useDispatchHelpers } from "../../store/dispatchhelper";
import Banner from "../../components/banner/banner";
import WebLoader from "../../components/webLoader/WebLoader";
import StandardCard from "./../../components/standardCard/standardCard";
import translate from "../../services/translation.json";
const TrueHand = () => {
  const {
    state: { baseUrl, trueHand, lang },
  } = useContext(AppContext);
  const { dispatchAction } = useDispatchHelpers();
  const isDataReady = Object.keys(trueHand).length > 0;

  useEffect(() => {
    axios.get(`${baseUrl}truehand`).then((response) => {
      dispatchAction("setTrueHandData", response?.data?.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Find all span elements inside a div with class "page-container" that have style="color: white;"
    const spans = document.querySelectorAll(
      '.page-container span[style="color: white;"]'
    );
    // Add a class to each of these spans
    spans.forEach((span) => {
      span.classList.add("white-text");
    });
  }, [trueHand]);

  return (
    <React.Fragment>
      <WebLoader isLoading={!isDataReady} />
      <section className="true-hand page">
        {trueHand && isDataReady && (
          <React.Fragment>
            {
              <Banner
                mediaUrl={trueHand.banners[0].path}
                title={trueHand.banners[0]["title_" + lang]}
              />
            }
            <div className="page-container" id="page-container">
              <div className="card-wrapper">
                {trueHand.truehand.map((item, i) => {
                  return (
                    <StandardCard
                      title={item["title_" + lang]}
                      desc={item["description_" + lang]}
                      key={`public-card-${i}`}
                    />
                  );
                })}
              </div>
              <p className="contact-btn">{translate[lang].contactus}</p>
            </div>
          </React.Fragment>
        )}
      </section>
    </React.Fragment>
  );
};

export default TrueHand;
