import React, { useEffect, useContext, useState } from "react";
import { AppContext } from "../../store/appContext";
import axios from "axios";
import { useDispatchHelpers } from "../../store/dispatchhelper";
import Banner from "../../components/banner/banner";
import WebLoader from "../../components/webLoader/WebLoader";
import "./immobilien.scss";
import ServiceDescription from "../../components/serviceDescription/serviceDescription";

const Immobilien = () => {
  const {
    state: { baseUrl, immobielien, lang },
  } = useContext(AppContext);
  const { dispatchAction } = useDispatchHelpers();
  const [cardOpenedData, setCardOpenedData] = useState(false);
  const isDataReady = Object.keys(immobielien).length > 0;

  useEffect(() => {
    axios.get(`${baseUrl}immobielien-public`).then((response) => {
      dispatchAction("setImmobielienData", response?.data?.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Find all span elements inside a div with class "page-container" that have style="color: white;"
    const spans = document.querySelectorAll(
      '.page-container span[style="color: white;"]'
    );
    // Add a class to each of these spans
    spans.forEach((span) => {
      span.classList.add("white-text");
    });
  }, [immobielien, cardOpenedData]);

  const renderImmobielienList = () => {
    return (
      <div className="page-container" id="page-container">
        <h2
          className="title"
          dangerouslySetInnerHTML={{
            __html: immobielien.immobielien_public.header["title_" + lang],
          }}
        />
        <div className="immobielien-body">
          {immobielien.immobielien_public.data.map((item, i) => {
            return (
              <div
                key={`immobielien-card-${i}`}
                className="card"
                onClick={() => setCardOpenedData(item)}
              >
                <img src={item.carosel_images[0]} alt="immobielien-image" />
                <div className="card-content">
                  <h3
                    className="card-title"
                    dangerouslySetInnerHTML={{
                      __html: item["title_" + lang],
                    }}
                  />
                  <p
                    className="card-desc"
                    dangerouslySetInnerHTML={{
                      __html: item["description_" + lang],
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <WebLoader isLoading={!isDataReady} />
      <div className="immobielien-page page">
        {immobielien && isDataReady && (
          <React.Fragment>
            <Banner
              mediaUrl={immobielien.banners[0].path}
              title={immobielien.banners[0]["title_" + lang]}
            />
            {!cardOpenedData ? (
              renderImmobielienList()
            ) : (
              <ServiceDescription
                data={cardOpenedData}
                close={() => {
                  setCardOpenedData(false);
                }}
                lang={lang}
              />
            )}
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

export default Immobilien;
