import React, { useEffect, useContext } from "react";
import { AppContext } from "../../store/appContext";
import axios from "axios";
import { useDispatchHelpers } from "../../store/dispatchhelper";
import Banner from "../../components/banner/banner";
import WebLoader from "../../components/webLoader/WebLoader";
import "./energie-losungen.scss";
import energieImage from "./../../assets/images/energie.png";
const EnergyLosungen = () => {
  const {
    state: { baseUrl, energieLosungen, lang },
  } = useContext(AppContext);
  const { dispatchAction } = useDispatchHelpers();
  const isDataReady = Object.keys(energieLosungen).length > 0;

  useEffect(() => {
    axios.get(`${baseUrl}energie-lösungen`).then((response) => {
      dispatchAction("setEnergieLosungenData", response?.data?.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    // Find all span elements inside a div with class "page-container" that have style="color: white;"
    const spans = document.querySelectorAll(
      '.page-container span[style="color: white;"]'
    );
    // Add a class to each of these spans
    spans.forEach((span) => {
      span.classList.add("white-text");
    });
  }, [energieLosungen]);
  return (
    <React.Fragment>
      <WebLoader isLoading={!isDataReady} />
      <div className="energie-losungen-page page">
        {energieLosungen && isDataReady && (
          <React.Fragment>
            <Banner
              mediaUrl={energieLosungen.banners[0].path}
              title={energieLosungen.banners[0]["title_" + lang]}
            />
            <div className="page-container" id="page-container">
              <div className="energie-losungen-body">
                <p
                  className="energie-losungen-body-desc"
                  dangerouslySetInnerHTML={{
                    __html:
                      energieLosungen.energie_losungen[0][
                        "description_" + lang
                      ],
                  }}
                />
                <div className="products-container">
                  <div className="products-details">
                    {/* <h2>Products</h2>
                {
                    energieLosungen.energie_losungen[0].product_files.map((item, i) => {
                      const url = item.files_zip_url;
                      return(
                        <a className={`product-name-${i+1} ${url?'':'d-none'}`}>{item['data_'+lang]}</a>
                      )
                  })
                } */}
                  </div>
                  <div className="products-image">
                    {energieLosungen.energie_losungen[0].product_files.map(
                      (item, i) => {
                        const url = item.files_zip_url;
                        return (
                          <div
                            key={`product-${i}`}
                            className={`product-${i + 1} ${
                              url ? "" : "d-none"
                            }`}
                          >
                            <a
                              href={url}
                              download={url.substring(url.lastIndexOf("/") + 1)}
                            >
                              {" "}
                            </a>
                          </div>
                        );
                      }
                    )}
                    <img src={energieImage} alt="energie-products" />
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

export default EnergyLosungen;
