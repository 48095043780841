import React, { useEffect, useContext } from "react";
import axios from "axios";
import { AppContext } from "../../store/appContext";
import { useDispatchHelpers } from "../../store/dispatchhelper";
import { useForm, Controller } from "react-hook-form";
import { TextField } from "@mui/material";
import Banner from "../../components/banner/banner";
import WebLoader from "../../components/webLoader/WebLoader";
import "./betrugspravention.scss";

let inputFontColor = "white";

const Betrugspravention = () => {
  const {
    state: { baseUrl, betrugspravention, lang, lightMode },
  } = useContext(AppContext);
  const { dispatchAction } = useDispatchHelpers();
  const isDataReady = Object.keys(betrugspravention).length > 0;
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    console.log(data);
  };
  useEffect(() => {
    axios.get(`${baseUrl}betrugsprävention`).then((response) => {
      dispatchAction("setBetrugspraventionData", response?.data?.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    // Find all span elements inside a div with class "page-container" that have style="color: white;"
    const spans = document.querySelectorAll(
      '.page-container span[style="color: white;"]'
    );
    inputFontColor = lightMode ? "#131A3B" : "white";
    // Add a class to each of these spans
    spans.forEach((span) => {
      span.classList.add("white-text");
    });
  }, [betrugspravention, lightMode]);

  const renderForm = () => {
    return (
      <div className="betrugspravention-form">
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
          <div className="input-container">
            <p>Name</p>
            <Controller
              name="name"
              control={control}
              defaultValue=""
              rules={{ required: "Name is required" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  InputLabelProps={{
                    style: { color: "#C2A665", borderColor: "#C2A665" },
                  }}
                  InputProps={{
                    style: { color: inputFontColor }, // Set font color to white
                  }}
                  sx={{
                    "& .MuiFilledInput-root": {
                      "&:before": {
                        borderBottomColor: "#C2A665", // Default border color
                      },
                      "&:hover:not(.Mui-disabled):before": {
                        borderBottomColor: "#C2A665", // Hover border color
                      },
                      "&:after": {
                        borderBottomColor: "transparent", // Focused border color
                      },
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#C2A665", // Label color when focused
                    },
                  }}
                  label="Enter Name"
                  variant="filled"
                  error={!!errors.name}
                  helperText={errors.name ? errors.name.message : ""}
                  className="mui-textfield"
                />
              )}
            />
          </div>
          <div className="input-container">
            <p>Address</p>
            <Controller
              name="address"
              control={control}
              defaultValue=""
              rules={{ required: "Address is required" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  InputLabelProps={{
                    style: { color: "#C2A665" },
                  }}
                  InputProps={{
                    style: { color: inputFontColor }, // Set font color to white
                  }}
                  sx={{
                    "& .MuiFilledInput-root": {
                      "&:before": {
                        borderBottomColor: "#C2A665", // Default border color
                      },
                      "&:hover:not(.Mui-disabled):before": {
                        borderBottomColor: "#C2A665", // Hover border color
                      },
                      "&:after": {
                        borderBottomColor: "transparent", // Focused border color
                      },
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#C2A665", // Label color when focused
                    },
                  }}
                  label="Enter Address"
                  variant="filled"
                  error={!!errors.address}
                  helperText={errors.address ? errors.address.message : ""}
                  className="mui-textfield"
                />
              )}
            />
          </div>
          <div className="input-container">
            <p>PLZ</p>
            <Controller
              name="plz"
              control={control}
              defaultValue=""
              rules={{ required: "PLZ is required" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  InputLabelProps={{
                    style: { color: "#C2A665" },
                  }}
                  InputProps={{
                    style: { color: inputFontColor }, // Set font color to white
                  }}
                  sx={{
                    "& .MuiFilledInput-root": {
                      "&:before": {
                        borderBottomColor: "#C2A665", // Default border color
                      },
                      "&:hover:not(.Mui-disabled):before": {
                        borderBottomColor: "#C2A665", // Hover border color
                      },
                      "&:after": {
                        borderBottomColor: "transparent", // Focused border color
                      },
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#C2A665", // Label color when focused
                    },
                  }}
                  label="Enter PLZ"
                  variant="filled"
                  error={!!errors.plz}
                  helperText={errors.plz ? errors.plz.message : ""}
                  className="mui-textfield"
                />
              )}
            />
          </div>
          <div className="input-container">
            <p>ORT</p>
            <Controller
              name="ort"
              control={control}
              defaultValue=""
              rules={{ required: "ORT is required" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  InputLabelProps={{
                    style: { color: "#C2A665" },
                  }}
                  InputProps={{
                    style: { color: inputFontColor }, // Set font color to white
                  }}
                  sx={{
                    "& .MuiFilledInput-root": {
                      "&:before": {
                        borderBottomColor: "#C2A665", // Default border color
                      },
                      "&:hover:not(.Mui-disabled):before": {
                        borderBottomColor: "#C2A665", // Hover border color
                      },
                      "&:after": {
                        borderBottomColor: "transparent", // Focused border color
                      },
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#C2A665", // Label color when focused
                    },
                  }}
                  label="Enter ORT"
                  variant="filled"
                  error={!!errors.ort}
                  helperText={errors.ort ? errors.ort.message : ""}
                  className="mui-textfield"
                />
              )}
            />
          </div>
          <div className="input-container">
            <p>Email</p>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{ required: "Email is required" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  InputLabelProps={{
                    style: { color: "#C2A665" },
                  }}
                  InputProps={{
                    style: { color: inputFontColor }, // Set font color to white
                  }}
                  sx={{
                    "& .MuiFilledInput-root": {
                      "&:before": {
                        borderBottomColor: "#C2A665", // Default border color
                      },
                      "&:hover:not(.Mui-disabled):before": {
                        borderBottomColor: "#C2A665", // Hover border color
                      },
                      "&:after": {
                        borderBottomColor: "transparent", // Focused border color
                      },
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#C2A665", // Label color when focused
                    },
                  }}
                  label="Enter Email"
                  variant="filled"
                  error={!!errors.email}
                  helperText={errors.email ? errors.email.message : ""}
                  className="mui-textfield"
                />
              )}
            />
          </div>
          <div className="input-container">
            <p>
              Name des Finanzdienstleister (natürliche- oder juristische Person)
            </p>
            <Controller
              name="finanz_name"
              control={control}
              defaultValue=""
              rules={{ required: "Name des Finanzdienstleister is required" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  InputLabelProps={{
                    style: { color: "#C2A665" },
                  }}
                  InputProps={{
                    style: { color: inputFontColor }, // Set font color to white
                  }}
                  sx={{
                    "& .MuiFilledInput-root": {
                      "&:before": {
                        borderBottomColor: "#C2A665", // Default border color
                      },
                      "&:hover:not(.Mui-disabled):before": {
                        borderBottomColor: "#C2A665", // Hover border color
                      },
                      "&:after": {
                        borderBottomColor: "transparent", // Focused border color
                      },
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#C2A665", // Label color when focused
                    },
                  }}
                  label="Enter Name des Finanzdienstleister"
                  variant="filled"
                  error={!!errors.finanz_name}
                  helperText={
                    errors.finanz_name ? errors.finanz_name.message : ""
                  }
                  className="mui-textfield"
                />
              )}
            />
          </div>
          <div className="input-container">
            <p>Webseite Finanzdienstleister</p>
            <Controller
              name="website"
              control={control}
              defaultValue=""
              rules={{ required: "Webseite Finanzdienstleister is required" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  InputLabelProps={{
                    style: { color: "#C2A665" },
                  }}
                  InputProps={{
                    style: { color: inputFontColor }, // Set font color to white
                  }}
                  sx={{
                    "& .MuiFilledInput-root": {
                      "&:before": {
                        borderBottomColor: "#C2A665", // Default border color
                      },
                      "&:hover:not(.Mui-disabled):before": {
                        borderBottomColor: "#C2A665", // Hover border color
                      },
                      "&:after": {
                        borderBottomColor: "transparent", // Focused border color
                      },
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#C2A665", // Label color when focused
                    },
                  }}
                  label="Enter Webseite Finanzdienstleister"
                  variant="filled"
                  error={!!errors.website}
                  helperText={errors.website ? errors.website.message : ""}
                  className="mui-textfield"
                />
              )}
            />
          </div>
          <div className="input-container">
            <p>Angebot des Finanzdienstleisters</p>
            <Controller
              name="angebot"
              control={control}
              defaultValue=""
              rules={{
                required: "Angebot des Finanzdienstleisters is required",
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  InputLabelProps={{
                    style: { color: "#C2A665" },
                  }}
                  InputProps={{
                    style: { color: inputFontColor }, // Set font color to white
                  }}
                  sx={{
                    "& .MuiFilledInput-root": {
                      "&:before": {
                        borderBottomColor: "#C2A665", // Default border color
                      },
                      "&:hover:not(.Mui-disabled):before": {
                        borderBottomColor: "#C2A665", // Hover border color
                      },
                      "&:after": {
                        borderBottomColor: "transparent", // Focused border color
                      },
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#C2A665", // Label color when focused
                    },
                  }}
                  label="Enter Angebot des Finanzdienstleisters"
                  variant="filled"
                  error={!!errors.angebot}
                  helperText={errors.angebot ? errors.angebot.message : ""}
                  className="mui-textfield"
                />
              )}
            />
          </div>
          <div className="input-container">
            <p>Kurze Beschreibung des Vorfalls</p>
            <Controller
              name="vorfalls"
              control={control}
              defaultValue=""
              rules={{
                required: "Kurze Beschreibung des Vorfalls is required",
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  InputLabelProps={{
                    style: { color: "#C2A665" },
                  }}
                  InputProps={{
                    style: { color: inputFontColor }, // Set font color to white
                  }}
                  sx={{
                    "& .MuiFilledInput-root": {
                      "&:before": {
                        borderBottomColor: "#C2A665", // Default border color
                      },
                      "&:hover:not(.Mui-disabled):before": {
                        borderBottomColor: "#C2A665", // Hover border color
                      },
                      "&:after": {
                        borderBottomColor: "transparent", // Focused border color
                      },
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#C2A665", // Label color when focused
                    },
                  }}
                  label="Enter Kurze Beschreibung des Vorfalls"
                  variant="filled"
                  error={!!errors.vorfalls}
                  helperText={errors.vorfalls ? errors.vorfalls.message : ""}
                  className="mui-textfield"
                />
              )}
            />
          </div>
          <div className="input-container">
            <p>Kurze Beschreibung Ihres Anliegens</p>
            <Controller
              name="anliegens"
              control={control}
              defaultValue=""
              rules={{
                required: "Kurze Beschreibung Ihres Anliegens is required",
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  InputLabelProps={{
                    style: { color: "#C2A665" },
                  }}
                  InputProps={{
                    style: { color: inputFontColor }, // Set font color to white
                  }}
                  sx={{
                    "& .MuiFilledInput-root": {
                      "&:before": {
                        borderBottomColor: "#C2A665", // Default border color
                      },
                      "&:hover:not(.Mui-disabled):before": {
                        borderBottomColor: "#C2A665", // Hover border color
                      },
                      "&:after": {
                        borderBottomColor: "transparent", // Focused border color
                      },
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#C2A665", // Label color when focused
                    },
                  }}
                  label="Enter Kurze Beschreibung Ihres Anliegens"
                  variant="filled"
                  error={!!errors.anliegens}
                  helperText={errors.anliegens ? errors.anliegens.message : ""}
                  className="mui-textfield"
                />
              )}
            />
          </div>

          <button type="submit" className="submit-btn">
            Senden
          </button>
        </form>
      </div>
    );
  };

  return (
    <React.Fragment>
      <WebLoader isLoading={!isDataReady} />
      <div className="betrugspravention-page page">
        {betrugspravention && isDataReady && (
          <React.Fragment>
            <Banner
              mediaUrl={betrugspravention.banners[0].path}
              title={betrugspravention.banners[0]["title_" + lang]}
            />
            <div className="page-container" id="page-container">
              <div className="details">
                <h2
                  className="title"
                  dangerouslySetInnerHTML={{
                    __html:
                      betrugspravention.betrugspravention[0]["title_" + lang],
                  }}
                />
                <p
                  className="desc"
                  dangerouslySetInnerHTML={{
                    __html:
                      betrugspravention.betrugspravention[0][
                        "description_" + lang
                      ],
                  }}
                />
              </div>
              {renderForm()}
            </div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

export default Betrugspravention;
