import "./partners.scss";
import WebLoader from "../../components/webLoader/WebLoader";
import React, { useEffect, useContext, useState } from "react";
import { AppContext } from "../../store/appContext";
import axios from "axios";
import { useDispatchHelpers } from "../../store/dispatchhelper";
import { Link } from "react-router-dom";
const Partners = () => {
  const {
    state: { baseUrl, partners, lang },
  } = useContext(AppContext);
  const { dispatchAction } = useDispatchHelpers();
  const isDataReady = Object.keys(partners).length > 0;
  const [selectedCategory, setSelectedCategory] = useState();

  useEffect(() => {
    axios.get(`${baseUrl}partners`).then((response) => {
      dispatchAction("setPartnersData", response?.data?.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderPartnersDetails = (partner) => {
    return (
      <div
        className="logo-container"
        onClick={() => setSelectedCategory(partner)}
      >
        {partner.partner_details.map((item, i) => {
          if (i >= 4) {
            return null; // Break the loop after 4th index
          }
          return (
            <div className="partners-logo" key={`partners-logo-${i}`}>
              <img src={item.logo} alt="partner-logo" />
            </div>
          );
        })}
      </div>
    );
  };
  const renderPartnerPopup = () => {
    return (
      <div className="logo-container" onClick={(e) => e.stopPropagation()}>
        {selectedCategory.partner_details.map((item, i) => {
          return (
            <Link
              to={item.link}
              target="_blank"
              rel="noopener noreferrer"
              className="partners-logo"
              key={`partners-logo-${i}`}
            >
              <img src={item.logo} alt="partner-logo" />
            </Link>
          );
        })}
      </div>
    );
  };

  return (
    <React.Fragment>
      <WebLoader isLoading={!isDataReady} />
      <div className="page page-container partners-page">
        {partners && isDataReady && (
          <React.Fragment>
            <h1
              className="page-title"
              dangerouslySetInnerHTML={{
                __html: partners.partners.header["title_" + lang],
              }}
            />
            <div className="partners-page-content">
              <div className="partners-page-content-wrapper">
                {partners.partners.data.map((item, i) => {
                  return (
                    <div className="partners-card" key={`partners-card-${i}`}>
                      <h3
                        className="partners-card-title"
                        dangerouslySetInnerHTML={{
                          __html: item["category_" + lang],
                        }}
                      />
                      {renderPartnersDetails(item)}
                    </div>
                  );
                })}
                {selectedCategory && (
                  <div
                    className="popup-container"
                    onClick={() => {
                      setSelectedCategory(false);
                    }}
                  >
                    {renderPartnerPopup()}
                  </div>
                )}
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

export default Partners;
