import React from "react";
import "./contactUs.scss";
import translate from "../../services/translation.json";
import { useForm, Controller } from "react-hook-form";
// import axios from 'axios';
import { TextField } from "@mui/material";

const ContactUs = ({ lang, close, page, lightMode }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <div className={`contact-us ${lightMode? '': 'light'}`}>
      <div className="close-btn-container">
        <p className="close-btn" onClick={() => close(false)}>
          X
        </p>
      </div>
      <h1>{translate[lang].contactus}</h1>
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="name"
          control={control}
          defaultValue=""
          rules={{ required: "Name is required" }}
          render={({ field }) => (
            <TextField
              {...field}
              InputLabelProps={{
                style: { color: "#C2A665" },
              }}
              sx={{
                backgroundColor: "white",
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "white",
                  "& fieldset": {
                    borderColor: "transparent", // Remove border between input and icon
                  },
                  "&:hover fieldset": {
                    borderColor: "transparent", // Remove border on hover
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent", // Remove border on focus
                  },
                },
              }}
              label="Name"
              variant="filled"
              error={!!errors.name}
              helperText={errors.name ? errors.name.message : ""}
              className="mui-textfield"
            />
          )}
        />
        <Controller
          name="email"
          control={control}
          defaultValue=""
          rules={{ required: "Email is required" }}
          render={({ field }) => (
            <TextField
              InputLabelProps={{
                style: { color: "#C2A665" },
              }}
              sx={{
                backgroundColor: "white",
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "white",
                  "& fieldset": {
                    borderColor: "transparent", // Remove border between input and icon
                  },
                  "&:hover fieldset": {
                    borderColor: "transparent", // Remove border on hover
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent", // Remove border on focus
                  },
                },
              }}
              {...field}
              label="Email"
              variant="filled"
              error={!!errors.email}
              helperText={errors.email ? errors.email.message : ""}
              fullWidth
              margin="normal"
              className="mui-textfield"
            />
          )}
        />
        <Controller
          name="subject"
          control={control}
          defaultValue={page}
          rules={{ required: "Subject is required" }}
          render={({ field }) => (
            <TextField
              InputLabelProps={{
                style: { color: "#C2A665" },
              }}
              sx={{
                backgroundColor: "white",
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "white",
                  "& fieldset": {
                    borderColor: "transparent", // Remove border between input and icon
                  },
                  "&:hover fieldset": {
                    borderColor: "transparent", // Remove border on hover
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent", // Remove border on focus
                  },
                },
              }}
              {...field}
              label="Subject"
              variant="filled"
              error={!!errors.subject}
              helperText={errors.subject ? errors.subject.message : ""}
              fullWidth
              margin="normal"
              className="mui-textfield"
            />
          )}
        />

        <Controller
          name="message"
          control={control}
          defaultValue=""
          rules={{ required: "Message is required" }}
          render={({ field }) => (
            <TextField
              InputLabelProps={{
                style: { color: "#C2A665" },
              }}
              sx={{
                backgroundColor: "white",
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "white",
                  "& fieldset": {
                    borderColor: "transparent", // Remove border between input and icon
                  },
                  "&:hover fieldset": {
                    borderColor: "transparent", // Remove border on hover
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent", // Remove border on focus
                  },
                },
              }}
              {...field}
              label="Message"
              variant="filled"
              error={!!errors.message}
              helperText={errors.message ? errors.message.message : ""}
              fullWidth
              margin="normal"
              multiline
              rows={4}
              className="mui-textfield"
            />
          )}
        />
        <button type="submit" className="submit-btn">
          Contact
        </button>
      </form>
    </div>
  );
};

export default ContactUs;
