import React, { useContext, useEffect, useState } from "react";
// import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import axios from "axios";
import { AppContext } from "../../store/appContext";
import { useDispatchHelpers } from "../../store/dispatchhelper";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./header.scss";
import LangImg_en from "./../../assets/images/lang-en.png";
import LangImg_fr from "./../../assets/images/lang-fr.png";
import LangImg_de from "./../../assets/images/lang-de.png";
import LangImg_it from "./../../assets/images/lang-it.png";
import ArrowDownImg from "./../../assets/images/arrow-down.svg";
import ArrowRightImg from "./../../assets/images/right-arrow.svg";
import goldLogo from "./../../assets/images/logo-gold-header.svg";
import menuIcon from "./../../assets/images/burger-menu.png";
import contactUsImg from "./../../assets/images/contact-us.svg";
import ContactUs from "../contactUs/contactUs";
import darkModeIcon from "./../../assets/images/dark-mode-image.svg";
import lightModeIcon from "./../../assets/images/light-mode-image.svg";
const Header = () => {
  const {
    state: { baseUrl, header, lang, activePage },
  } = useContext(AppContext);
  const navigate = useNavigate();
  const { dispatchAction } = useDispatchHelpers();
  const { pathname } = useLocation();
  const [openDropDown, setOpenDropDown] = useState("");
  const [catOpenDropDown, setCatOpenDropDown] = useState("");
  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false);
  const [openMobileMenuClassName, setOpenMobileMenuClassName] = useState("");
  const [isContactOpen, setIsContactOpen] = useState(false);
  const [isLightMode, setIsLightMode] = useState(
    localStorage.getItem("lightMode") === "true" || false
  );

  useEffect(() => {
    axios.get(`${baseUrl}menu`).then((response) => {
      dispatchAction("setHeaderData", response?.data?.data);
    });
    changeTheme();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeTheme = () => {
    setIsLightMode(!isLightMode);
    if (isLightMode) {
      document.body.classList.add("light-mode");
      dispatchAction("setLightMode", true);
      localStorage.setItem("lightMode", true);
    } else {
      document.body.classList.remove("light-mode");
      dispatchAction("setLightMode", false);
      localStorage.setItem("lightMode", false);
    }
  };

  const getActiveHeaderItem = ({ pathname, header }) => {
    // if header is empty return
    if (!header.length) return false;

    // search for active page in the current level of the header
    const getActivePage = header.filter((headerItem) => {
      if (pathname === "/") return headerItem.slug === "edelwealth";
      return `/${headerItem.slug}` === pathname;
    });

    // if found in the current level, set active page
    if (getActivePage.length) {
      dispatchAction("setActivePageData", getActivePage[0]);
      return true;
    }

    // recursively search in children and subcategories
    for (const headerItem of header) {
      const { children, subcategories } = headerItem;

      if (children && getActiveHeaderItem({ pathname, header: children })) {
        return true;
      }

      if (
        subcategories &&
        getActiveHeaderItem({ pathname, header: subcategories })
      ) {
        return true;
      }
    }

    // if not found in any level, return false
    return false;
  };

  useEffect(() => {
    getActiveHeaderItem({ pathname, header });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, header]);

  //lang selection
  const getLangList = () => {
    return ["en", "fe", "de", "ita"];
  };

  const getLangImg = (language) => {
    switch (language) {
      case "en":
      default:
        return LangImg_en;
      case "fe":
        return LangImg_fr;
      case "de":
        return LangImg_de;
      case "ita":
        return LangImg_it;
    }
  };

  const handleSwitchLang = (lng) => {
    setOpenDropDown("");
    setCatOpenDropDown("");
    dispatchAction("setLang", lng);
  };

  const renderDesktopLangDropDown = () => {
    return (
      <div className="lang-selector desktop">
        <div className="header-lang ">
          <span className="header-lang-chosen">
            <img
              className="header-lang-img"
              src={getLangImg(lang)}
              alt="Language chosen"
            />
            <img
              className="header-lang-chosen-arr"
              src={ArrowDownImg}
              alt="open dropdown"
            />
          </span>
          <ul className="header-lang-list">
            {getLangList().map((lng, i) => {
              if (lng !== lang) {
                return (
                  <li
                    key={`header-lang-list-item-${i}`}
                    onClick={() => handleSwitchLang(lng)}
                  >
                    <img
                      className="header-lang-img"
                      src={getLangImg(lng)}
                      alt={`Choose Lang ${lng}`}
                    />
                  </li>
                );
              }
              return (
                <React.Fragment
                  key={`header-lang-list-item-${i}`}
                ></React.Fragment>
              );
            })}
          </ul>
        </div>
      </div>
    );
  };

  const renderDesktopNavigation = () => {
    return (
      header && (
        <nav className="header-nav desktop">
          <ul className="header-nav-list">
            {header.map((headerItem, i) => (
              <li
                key={`header-nav-list-item-${i}`}
                className="header-nav-list-item"
              >
                <Link
                  className={addActiveNavItemClassName(headerItem)}
                  to={`/${headerItem.slug}`}
                >
                  {headerItem[`title_${lang}`]}
                  {headerItem.children?.length > 0 && (
                    <img
                      className="header-nav-list-item-arr"
                      src={ArrowDownImg}
                      alt="open dropdown"
                    />
                  )}
                </Link>
                {headerItem.children?.length > 0 && (
                  <ul className="header-nav-list-item-dropdown">
                    {headerItem.children?.map((dropDownItem, i) => (
                      <li
                        key={`header-nav-list-item-dropdown-item-${i}`}
                        className="header-nav-list-item-dropdown-item"
                      >
                        <Link
                          className={addActiveNavItemClassName(dropDownItem)}
                          to={`/${dropDownItem.slug}`}
                        >
                          {dropDownItem[`title_${lang}`]}
                          {dropDownItem.subcategories?.length > 0 && (
                            <img
                              className="header-nav-list-item-arr"
                              src={ArrowRightImg}
                              alt="open dropdown"
                            />
                          )}
                        </Link>
                        {dropDownItem.subcategories?.length > 0 && (
                          <ul className="header-nav-list-item-dropdown-item-sub">
                            {dropDownItem.subcategories?.map((subItem, i) => (
                              <li
                                key={`header-nav-list-subcat-item-dropdown-item-${i}`}
                                className="header-nav-list-item-dropdown-item-sub-item"
                              >
                                <Link
                                  className={addActiveNavItemClassName(subItem)}
                                  to={`/${subItem.slug}`}
                                >
                                  {subItem[`title_${lang}`]}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </nav>
      )
    );
  };

  // --- handle navigation
  const addActiveNavItemClassName = ({ slug, children }) => {
    // Check if the current item's slug matches the active page's slug
    const isActiveMenuLink = activePage?.slug === slug;

    // Recursive function to check if any child or subchild is active
    const isChildActive = (items) => {
      // If there are no items, return false
      if (!items) return false;

      // Check if any item in the array is active
      return items.some((item) => {
        // If the item's slug matches the active page's slug, return true
        if (activePage.slug === item.slug) return true;

        // Recursively check the item's children and subcategories
        return (
          isChildActive(item.children) || isChildActive(item.subcategories)
        );
      });
    };

    // Return "active" if the current item or any child/subchild is active, otherwise return an empty string
    return isActiveMenuLink || isChildActive(children) ? "active" : "";
  };

  // --- handle mobile navigation
  const handleMobileNavigationOpen = (isOpen) => {
    if (isOpen) {
      setIsOpenMobileMenu(isOpen);
      setTimeout(() => {
        setOpenMobileMenuClassName("open");
      }, 50);
    } else {
      setOpenDropDown("");
      setCatOpenDropDown("");
      setOpenMobileMenuClassName("");
      setTimeout(() => {
        setIsOpenMobileMenu(false);
      }, 500);
    }
  };
  const renderMobileLangDropDown = () => {
    return (
      <div
        className={`header-mobile-nav-drawer-wrapper-content-dropdown lang ${
          openDropDown === "lang" && "open"
        }`}
      >
        <div className="menulink">
          <img src={getLangImg(lang)} alt="language selected" />
          <img
            src={ArrowDownImg}
            alt="arrow down button"
            className="open-dropdown"
            onClick={() =>
              setOpenDropDown(openDropDown === "lang" ? "" : "lang")
            }
          />
        </div>
        <div className="submenulinks">
          {getLangList().map((lng, i) => {
            if (lng !== lang) {
              return (
                <div
                  className="submenulink"
                  key={`lang-submenu-links-${i}`}
                  onClick={() => handleSwitchLang(lng)}
                >
                  <img src={getLangImg(lng)} alt={`Choose Lang ${lng}`} />
                </div>
              );
            }
            return (
              <React.Fragment key={`lang-submenu-links-${i}`}></React.Fragment>
            );
          })}
        </div>
      </div>
    );
  };
  const renderMobileNavDropDown = () => {
    return header.map((headerItem, i) => (
      <div
        key={"mobile-nav-dropdown-" + i + "-" + headerItem.slug}
        className={`header-mobile-nav-drawer-wrapper-content-dropdown nav ${
          openDropDown === headerItem.slug && "open"
        } ${addActiveNavItemClassName(headerItem)}`}
      >
        <div className={`menulink`}>
          <Link
            onClick={() => handleMobileNavigationOpen(false)}
            to={`/${headerItem.slug}`}
          >
            {headerItem[`title_${lang}`]}
          </Link>
          {headerItem.children && headerItem.children.length !== 0 && (
            <img
              src={ArrowDownImg}
              alt="arrow down button"
              className="open-dropdown"
              onClick={() =>
                setOpenDropDown(
                  openDropDown === headerItem.slug ? "" : headerItem.slug
                )
              }
            />
          )}
        </div>
        {headerItem.children && headerItem.children.length !== 0 && (
          <div className="submenulinks">
            {headerItem.children.map((dropDownItem, i) => (
              <React.Fragment key={"submenulinks-" + i}>
                <div
                  key={
                    "mobile-nav-dropdown-items-" + i + "-" + dropDownItem.slug
                  }
                  className={`submenulink ${
                    catOpenDropDown === dropDownItem.slug && "opened"
                  }`}
                >
                  <Link
                    onClick={() => handleMobileNavigationOpen(false)}
                    to={`/${dropDownItem.slug}`}
                  >
                    {dropDownItem[`title_${lang}`]}
                  </Link>
                  {dropDownItem.subcategories &&
                    dropDownItem.subcategories.length !== 0 && (
                      <img
                        src={ArrowDownImg}
                        alt="arrow down button"
                        className={`open-catg-dropdown`}
                        onClick={() =>
                          setCatOpenDropDown(
                            catOpenDropDown === dropDownItem.slug
                              ? ""
                              : dropDownItem.slug
                          )
                        }
                      />
                    )}
                </div>
                {dropDownItem.subcategories &&
                  dropDownItem.subcategories.length !== 0 && (
                    <div
                      className={`submcatlinks ${
                        catOpenDropDown === dropDownItem.slug &&
                        "open-categories"
                      }`}
                    >
                      {dropDownItem.subcategories.map((catg, i) => (
                        <div
                          key={
                            "mobile-nav-dropdown--subcat-items-" +
                            i +
                            "-" +
                            catg.slug
                          }
                          className="submcatgenulink"
                        >
                          <Link
                            onClick={() => handleMobileNavigationOpen(false)}
                            to={`/${catg.slug}`}
                          >
                            {catg[`title_${lang}`]}
                          </Link>
                        </div>
                      ))}
                    </div>
                  )}
              </React.Fragment>
            ))}
          </div>
        )}
      </div>
    ));
  };
  const renderMobileNavigation = () => {
    return (
      <>
        {
          <div className="header-mobile-nav mobile">
            <img
              onClick={() => handleMobileNavigationOpen(true)}
              alt="open mobile navigation menu"
              src={menuIcon}
            />
            {isOpenMobileMenu && (
              <div
                className={`header-mobile-nav-drawer ${openMobileMenuClassName}`}
              >
                <div
                  className="header-mobile-nav-drawer-drop"
                  onClick={() => handleMobileNavigationOpen(false)}
                ></div>
                <div className="header-mobile-nav-drawer-wrapper">
                  <div className="header-mobile-nav-drawer-wrapper-content">
                    <div className="header-mobile-nav-drawer-wrapper-content-logo">
                      <Link
                        to="/"
                        onClick={() => handleMobileNavigationOpen(false)}
                      >
                        <img src={goldLogo} alt="logo" />
                      </Link>
                    </div>
                    {renderMobileLangDropDown()}
                    {renderMobileNavDropDown()}
                  </div>
                </div>
              </div>
            )}
          </div>
        }
      </>
    );
  };
  const renderContactUs = () => {
    return (
      <div className="contact-us-btn" onClick={() => setIsContactOpen(true)}>
        <img src={contactUsImg} alt="contact us" />
      </div>
    );
  };

  const renderChangeTheme = () => {
    return (
      <div
        className={`theme-btn ${isLightMode ? "" : "light-mode-on"}`}
        onClick={() => changeTheme()}
      >
        <img
          src={isLightMode ? darkModeIcon : lightModeIcon}
          alt="contact us"
        />
      </div>
    );
  };

  return (
    <div className="header">
      <div className="header-container">
        <div className="logo">
          <img src={goldLogo} alt="logo" onClick={() => navigate("/home")} />
        </div>
        {renderDesktopNavigation()}
        {renderDesktopLangDropDown()}
        {renderMobileNavigation()}
        {renderContactUs()}
        {renderChangeTheme()}
        {isContactOpen && (
          <div className="contact-us-popup">
            <div className="contact-us-popup-content">
              <ContactUs
                lang={lang}
                close={setIsContactOpen}
                page={activePage["title_" + lang]}
                lightMode={isLightMode}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
