import React,{useEffect,useContext} from 'react';
import { AppContext } from '../../store/appContext';
import "./edelmetalle.scss";
import axios from "axios";
import { useDispatchHelpers } from "../../store/dispatchhelper";
import Banner from '../../components/banner/banner';
import WebLoader from '../../components/webLoader/WebLoader';
import LiveChart from '../../components/liveChart/liveChart';
const Edelmetalle = () => {

  const {
    state: { baseUrl, edelmetalle, lang },
  } = useContext(AppContext);
  const { dispatchAction } = useDispatchHelpers();
  const isDataReady = Object.keys(edelmetalle).length > 0;

  useEffect(() => {
    axios.get(`${baseUrl}edelmetalle`).then((response) => {
      dispatchAction("setEdelmetalleData", response?.data?.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Find all span elements inside a div with class "page-container" that have style="color: white;"
    const spans = document.querySelectorAll('.page-container span[style="color: white;"]');
    // Add a class to each of these spans
    spans.forEach(span => {
      span.classList.add('white-text');
    });
  }, [edelmetalle]);
  
  return (
    <React.Fragment>
      <WebLoader isLoading={!isDataReady} />
      <div className="edelmetalle-page page">
      {edelmetalle && isDataReady && (
        <React.Fragment>
        {<Banner mediaUrl={edelmetalle.banners[0].path} title={edelmetalle.banners[0]["title_"+lang]}/>}
        <div className="page-container" id='page-container'>
          <h2 className="title" dangerouslySetInnerHTML={{ __html: edelmetalle.edelmetalle[0]["title_"+lang] }} />
          <LiveChart />
          <p className="desc" dangerouslySetInnerHTML={{ __html: edelmetalle.edelmetalle[0]["description_"+lang] }} />
        </div>
        </React.Fragment>)}
      </div>
    </React.Fragment>
  );
}

export default Edelmetalle;