import React, { useEffect, useContext } from "react";
import { AppContext } from "../../store/appContext";
import axios from "axios";
import { useDispatchHelpers } from "../../store/dispatchhelper";
import Banner from "../../components/banner/banner";
import WebLoader from "../../components/webLoader/WebLoader";
import "./finanz.scss";

const Finanz = () => {
  const {
    state: { baseUrl, finanz, lang },
  } = useContext(AppContext);
  const { dispatchAction } = useDispatchHelpers();
  const isDataReady = Object.keys(finanz).length > 0;

  useEffect(() => {
    axios.get(`${baseUrl}finanz`).then((response) => {
      dispatchAction("setFinanzData", response?.data?.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    // Find all span elements inside a div with class "page-container" that have style="color: white;"
    const spans = document.querySelectorAll(
      '.page-container span[style="color: white;"]'
    );
    // Add a class to each of these spans
    spans.forEach((span) => {
      span.classList.add("white-text");
    });
  }, [finanz]);
  return (
    <React.Fragment>
      <WebLoader isLoading={!isDataReady} />
      <div className="finanz-page page">
        {finanz && isDataReady && (
          <React.Fragment>
            {
              <Banner
                mediaUrl={finanz.banners[0].path}
                title={finanz.banners[0]["title_" + lang]}
              />
            }
            <div className="page-container" id="page-container">
              <h2
                className="title"
                dangerouslySetInnerHTML={{
                  __html: finanz.finanz.data[0]["title_" + lang],
                }}
              />
              <img src={finanz.finanz.data[0].path} alt="finanz-image" />
              <p
                className="desc"
                dangerouslySetInnerHTML={{
                  __html: finanz.finanz.data[0]["description_" + lang],
                }}
              />
              <div className="three-cards">
                {finanz.finanz.interest_rates.map((item, i) => {
                  return (
                    <div key={`finanz-card-${i}`} className="card">
                      <h3
                        className="card-title"
                        dangerouslySetInnerHTML={{
                          __html: item["short_term_title_" + lang],
                        }}
                      />
                      <p
                        className="card-desc"
                        dangerouslySetInnerHTML={{
                          __html: item["short_term_description_" + lang],
                        }}
                      />
                    </div>
                  );
                })}
              </div>
              <div className="quote">
                <h3
                  className="title"
                  dangerouslySetInnerHTML={{
                    __html:
                      finanz.finanz.metals[0]["appointment_title_" + lang],
                  }}
                />
                <p
                  className="desc"
                  dangerouslySetInnerHTML={{
                    __html:
                      finanz.finanz.metals[0][
                        "appointment_description_" + lang
                      ],
                  }}
                />
                <p
                  className="quote-btn"
                  dangerouslySetInnerHTML={{
                    __html: finanz.finanz.metals[0]["appointment_chip_" + lang],
                  }}
                />
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

export default Finanz;
