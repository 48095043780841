import React, { useEffect, useContext } from "react";
import "./versicherung.scss";
import { AppContext } from "../../store/appContext";
import axios from "axios";
import { useDispatchHelpers } from "../../store/dispatchhelper";
import VersicherungCard from "../../components/versicherungCard/versicherungCard";
import Banner from "../../components/banner/banner";
import WebLoader from "../../components/webLoader/WebLoader";

const Versicherung = () => {
  const {
    state: { baseUrl, versicherung, lang },
  } = useContext(AppContext);
  const { dispatchAction } = useDispatchHelpers();
  const isDataReady = Object.keys(versicherung).length > 0;

  useEffect(() => {
    axios.get(`${baseUrl}versicherung-company`).then((response) => {
      dispatchAction("setVersicherungData", response?.data?.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Find all span elements inside a div with class "page-container" that have style="color: white;"
    const spans = document.querySelectorAll(
      '.page-container span[style="color: white;"]'
    );
    // Add a class to each of these spans
    spans.forEach((span) => {
      span.classList.add("white-text");
    });
  }, [versicherung]);

  return (
    <React.Fragment>
      <WebLoader isLoading={!isDataReady} />
      <section className="versicherung page">
        {versicherung && isDataReady && (
          <React.Fragment>
            {
              <Banner
                mediaUrl={versicherung.banners[0].path}
                title={versicherung.banners[0]["title_" + lang]}
              />
            }
            <div className="page-container" id="page-container">
              <h2
                className="title"
                dangerouslySetInnerHTML={{
                  __html:
                    versicherung.versicherung_company.header["title_" + lang],
                }}
              />
              {versicherung.versicherung_company.data.map((item, i) => {
                return (
                  <VersicherungCard
                    title={item["title_" + lang]}
                    desc={item["description_" + lang]}
                    key={`public-card-${i}`}
                  />
                );
              })}
            </div>
          </React.Fragment>
        )}
      </section>
    </React.Fragment>
  );
};

export default Versicherung;
