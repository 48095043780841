import React, { useEffect, useContext, useState } from "react";
import { AppContext } from "../../store/appContext";
import axios from "axios";
import { useDispatchHelpers } from "../../store/dispatchhelper";
import Banner from "../../components/banner/banner";
import WebLoader from "../../components/webLoader/WebLoader";
import "./cashBack.scss";
import ServiceDescription from "../../components/serviceDescription/serviceDescription";

const CashBack = () => {
  const {
    state: { baseUrl, cashBack, lang },
  } = useContext(AppContext);
  const { dispatchAction } = useDispatchHelpers();
  const isDataReady = Object.keys(cashBack).length > 0;
  const [cardOpenedData, setCardOpenedData] = useState(false);

  useEffect(() => {
    axios.get(`${baseUrl}cash-back`).then((response) => {
      dispatchAction("setCashBackData", response?.data?.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Find all span elements inside a div with class "page-container" that have style="color: white;"
    const spans = document.querySelectorAll(
      '.page-container span[style="color: white;"]'
    );
    // Add a class to each of these spans
    spans.forEach((span) => {
      span.classList.add("white-text");
    });
  }, [cashBack]);

  const renderCashBackList = () => {
    return (
      <div className="page-container" id="page-container">
        <div className="cash-back-body">
          {cashBack.cash_back.map((item, i) => {
            const images = item.carosel_images;
            return (
              <div
                key={`cash-back-card-${i}`}
                className="card"
                onClick={() => setCardOpenedData(item)}
              >
                <img src={images} alt="cash-back-image" />
                <div className="card-content">
                  <h3
                    className="card-title"
                    dangerouslySetInnerHTML={{
                      __html: item["title_" + lang],
                    }}
                  />
                  <p
                    className="card-desc"
                    dangerouslySetInnerHTML={{
                      __html: item["description_" + lang],
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <WebLoader isLoading={!isDataReady} />
      <div className="cash-back-page page">
        {cashBack && isDataReady && (
          <React.Fragment>
            <Banner
              mediaUrl={cashBack.banners[0].path}
              title={cashBack.banners[0]["title_" + lang]}
            />
            {!cardOpenedData ? (
              renderCashBackList()
            ) : (
              <ServiceDescription
                data={cardOpenedData}
                close={() => {
                  setCardOpenedData(false);
                }}
                lang={lang}
              />
            )}
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

export default CashBack;
