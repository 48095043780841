import React,{useState} from "react";
import "./App.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AppProvider } from "./store/appContext";
import Homepage from "./pages/homepage/homepage";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import ScrollToTop from "./components/ScrollToTop";
import Edelmetalle from "./pages/edelmetalle/edelmetalle";
import CashBack from "./pages/cashBack/cashBack";
import EnergyLosungen from "./pages/energie-losungen/energie-losungen";
import Finanz from "./pages/finanz/finanz";
import Immobilien from "./pages/immobilien/immobilien";
import ImmobilienPrivate from "./pages/immobilienPrivate/immobilienPrivate";
import ITLosungen from "./pages/IT-Losungen/IT-Losungen";
import Partners from "./pages/partners/partners";
import TrueHand from "./pages/trueHand/trueHand";
import Versicherung from "./pages/versicherung/versicherung";
// import Calculator from "./pages/calculator/calculator";
import Uber from "./pages/uber/uber";
import VersicherungPrivate from "./pages/versicherungPrivate/versicherungPrivate";
import Login from "./components/login/login";
import PrivateRoute from "./components/privateRoute/PrivateRoute";
import Renovation from "./pages/renovation/renovation";
import RenovationDetails from "./pages/renovationDetails/renovationDetails";
import Betrugspravention from "./pages/betrugspravention/betrugspravention";
function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(sessionStorage.getItem("token")? true : false);
  
  return (
    <AppProvider>
      <BrowserRouter>
        <ScrollToTop />
        <Header />
        <Routes>
        <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
        <Route element={<PrivateRoute isAuthenticated={isAuthenticated} />}>
          <Route path="/Immobielien-private" element={<ImmobilienPrivate />} />
        </Route>
          <Route path="/home" element={<Homepage />} />
          <Route path="/cash-back" element={<CashBack />} />
          <Route path="/edelmetalle" element={<Edelmetalle />} />
          {/* <Route path="/edelmetalle/calculator" element={<Calculator />} /> */}
          <Route path="/edelmetalle-uber" element={<Uber />} />
          <Route path="/energie-lösungen" element={<EnergyLosungen />} />
          <Route path="/finanz" element={<Finanz />} />
          <Route path="/immobielien" element={<Immobilien />} />
          <Route path="/immobielien-public" element={<Immobilien />} />
          <Route path="/immobielien-rennovation" exact element={<Renovation />} />
          <Route path="/immobielien-rennovation/:id" element={<RenovationDetails/>} />
          <Route path="/login" element={<Login />} />
          <Route path="/it-losungen" element={<ITLosungen />} />
          <Route path="/partners" element={<Partners />} />
          <Route path="/truehand" element={<TrueHand />} />
          <Route path="/versicherung" element={<Versicherung />} />
          <Route path="/versicherung-company" element={<Versicherung />} />
          <Route path="/versicherung-private" element={<VersicherungPrivate />} />
          <Route path="/betrugsprävention" element={<Betrugspravention />} />
          <Route path="*" element={<Homepage />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </AppProvider>
  );
}

export default App;
